/* latin */
@font-face {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(../fonts/Outfit/QGYvz_MVcBeNP4NJtEtq.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(../fonts/Outfit/QGYvz_MVcBeNP4NJtEtq.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../fonts/Outfit/QGYvz_MVcBeNP4NJtEtq.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/Outfit/QGYvz_MVcBeNP4NJtEtq.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/Outfit/QGYvz_MVcBeNP4NJtEtq.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../fonts/Outfit/QGYvz_MVcBeNP4NJtEtq.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/Outfit/QGYvz_MVcBeNP4NJtEtq.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(../fonts/Outfit/QGYvz_MVcBeNP4NJtEtq.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(../fonts/Outfit/QGYvz_MVcBeNP4NJtEtq.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* @import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap"); */

body {
  margin: 0;
  font-family: Outfit;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  top: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  position: relative;
  min-height: 100%;
  top: 0px;
  width: 100%;
}

.padding {
  padding: 10px;
}

.dropdown-toggle::after {
  display: none;
  margin-left: auto;
  vertical-align: auto;
  content: "";
  border-top: none;
  border-right: none;
  border-bottom: initial;
  border-left: none;
}
a:hover {
  text-decoration: none;
}
.nopadding {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

/*---gallary-slider------*/

.gallery-container {
  margin-top: 50px;
}

.app-header {
  letter-spacing: 1px;
  text-transform: uppercase;
}

.close-video::before {
  content: "✖";
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 20px;
  padding: 20px;
  z-index: 1;
  line-height: 0.7;
  display: block;
  color: #fff;
}

.video-wrapper {
  position: relative;
  padding: 33.35% 0; /* 16:9 */
  height: 0;
}

.fullscreen .video-wrapper {
  padding: 22% 0; /* 16:9 */
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.app .image-gallery,
.app-sandbox {
  margin: 0 auto;
  width: 100%;
}

@media (max-width: 1320px) {
  .app-sandbox-content {
    padding: 0 20px;
  }
}

.app-sandbox {
  margin: 40px auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.app-buttons li {
  display: block;
}

@media (max-width: 768px) {
  .app-header {
    font-size: 20px;
  }

  .app-buttons li {
    display: block;
    margin: 10px 0;
  }

  .app-buttons li + li {
    padding: 0;
  }

  .play-button {
    height: 40px;
    width: 65px;
  }

  .play-button:after {
    top: 11px;
    left: 27px;
    border-width: 8.5px 0 8.5px 12px;
  }

  .close-video::before {
    font-size: 16px;
    padding: 15px;
  }
}

@media (max-width: 1024px) {
  .app .image-gallery,
  .app-sandbox {
    width: 100%;
  }
}

.app-interval-input-group {
  display: table;
}

.app-interval-label {
  display: table-cell;
  vertical-align: middle;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 3px solid #ccc;
  border-right: none;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.app-interval-input {
  -webkit-appearance: none;
  display: table-cell;
  margin: 0;
  padding: 9px;
  border-radius: 5px;
  font-size: 14px;
  border: 3px solid #ccc;
  background: #fff;
  width: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

input.app-interval-input {
  width: 65px;
}

.app-checkboxes {
  margin-top: 10px;
}

.app-checkboxes li {
  display: block;
}
/*---gallary-slider------*/

/* read more*/
.sidebar-box {
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.sidebar-box .read-more div {
  text-decoration: none;
  border-radius: 6px;
  padding: 7px 20px;
  position: relative;
  display: inline-block;
  font-size: 13px;
  margin-bottom: 7px;
  font-weight: 600;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.sidebar-box-dados {
  max-height: 650px;
}
.sidebar-box-caract {
  max-height: 120px;
}
.sidebar-box-obs {
  max-height: 275px;
}
.sidebar-box-equipamento {
  max-height: 464px;
}
.sidebar-box-menu {
  max-height: 250px;
}

@media (max-width: 991px) {
  .sidebar-box-equipamento-mobile {
    max-height: 280px;
  }
  .sidebar-box-equipamento {
    max-height: 100%;
  }
}

.sidebar-box-historico {
  max-height: 464px;
}
.sidebar-box-Max {
  max-height: 100%;
}
/* read more*/

.gqCpix {
  z-index: 9998 !important;
}
.goDVSy {
  z-index: 9999 !important;
}
.eHAZXF {
  z-index: 9999 !important;
}
.form-check-label {
  margin-bottom: 0;
  white-space: initial;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 15px;
  letter-spacing: 0.24px;
  line-height: 19px;
}

.btn-close:focus {
  outline: 0;
  box-shadow: none;
  opacity: 1;
}

/* tabs Pesquisa */

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent;
}
.nav-tabs .nav-link {
  border: none;
}

a {
  text-decoration: none;
}

.logoTranparent {
  position: absolute;
  top: 150px;
}
.logoTranparent img {
  width: 100%;
}
.grecaptcha-badge {
  display: none !important;
}

/* inicio destaques*/

.destaques-cover-flow .swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 5px;
}

.destaques-cover-flow .swiper-slide {
  filter: blur(4px);
  -webkit-filter: blur(4px);
  opacity: 0.8;
  pointer-events: none;
}
.destaques-cover-flow .swiper-slide.swiper-slide-active {
  filter: blur(0px);
  -webkit-filter: blur(0px);
  opacity: 1;
  pointer-events: visible;
}

.destaques-cover-flow .swiper-wrapper {
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .destaques-cover-flow .swiper-slide {
    filter: blur(0px);
    -webkit-filter: blur(0px);
    opacity: 1;
    pointer-events: visible;
  }
}

/* fim destaques*/

/* inicio Utimas Entradas*/
.recentes-slider .swiper-slide > div {
  padding: 0 15px;
}
@media (max-width: 991px) {
  .recentes-slider .swiper-slide > div {
    padding: 0 10px;
  }
}
@media (max-width: 767px) {
  .recentes-slider .swiper-slide > div {
    padding: 0 7.5px;
  }
}
@media (max-width: 575px) {
  .recentes-slider .swiper-slide > div {
    padding: 0 5px;
  }
}

/* fim destaques*/

.grid-slider {
  border-radius: 32.4px;
  box-shadow: 0 12px 19px 0 rgb(0 0 0 / 19%);
  margin-bottom: 10px;
}
.Full-slider .swiper-slide {
  border-radius: 32.4px;
  overflow: hidden;
}

.Full-slider .swiper-pagination-fraction {
  background-color: rgb(45, 45, 45);
  color: rgb(255, 255, 255);
  padding: 4px 8px;
  border-radius: 4px;
  width: 80px;
  text-align: center;
  margin: auto;
  left: 0;
  right: 0;
  position: fixed;
  bottom: 5px;
}

.thumbs-slider .swiper-slide {
  border-radius: 20px;
  box-shadow: 0 12px 19px 0 rgb(0 0 0 / 19%);
  overflow: hidden;
  margin-bottom: 15px;
}

@media (max-width: 1199px) {
  .grid-slider2 {
    border-radius: 0px;
  }
}

@media (max-width: 767px) {
  .grid-slider {
    border-radius: 0;
  }
  .Full-slider .swiper-slide {
    border-radius: 8px;
    overflow: hidden;
  }
}

/*inicio carrousel imagens*/
.carousel-control-prev,
.carousel-control-next {
  opacity: 0;
  z-index: 4;
}
.slide.carousel:hover .carousel-control-prev,
.slide.carousel:hover .carousel-control-next,
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  opacity: 1;
}

.Destaques-Banners.swiper-horizontal > .swiper-pagination-bullets,
.Destaques-Banners.swiper-pagination-bullets.swiper-pagination-horizontal,
.Destaques-Banners.swiper-pagination-custom,
.Destaques-Banners.swiper-pagination-fraction {
  bottom: 85px;
}
.Destaques-Banners.top.swiper-horizontal > .swiper-pagination-bullets,
.Destaques-Banners.top.swiper-pagination-bullets.swiper-pagination-horizontal,
.Destaques-Banners.top.swiper-pagination-custom,
.Destaques-Banners.top.swiper-pagination-fraction {
  bottom: 10px;
}

@media (max-width: 767px) {
  .Destaques-Banners.top.swiper-horizontal > .swiper-pagination-bullets,
  .Destaques-Banners.top.swiper-pagination-bullets.swiper-pagination-horizontal,
  .Destaques-Banners.top.swiper-pagination-custom,
  .Destaques-Banners.top.swiper-pagination-fraction {
    bottom: 10px;
  }
}

.Destaques-Banners.top {
  margin-top: 20px;
}

.Destaques-Banners .swiper-pagination-bullet {
  background-color: rgba(255, 255, 255, 0.7) !important;
}
.Destaques-Banners .swiper-pagination-bullet-active {
  background-color: #ffffff !important;
}

.dest-listagem .carousel-inner {
  overflow: hidden;
  border-radius: 27px;
}

.dest-listagem2 .carousel-inner {
  overflow: hidden;
  border-radius: 27px;
}

.dest-listagem .carousel-indicators {
  margin: 0;
}
.dest-listagem .carousel-indicators [data-bs-target] {
  width: 1.5vw;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  transition: opacity 0.6s ease, background-color 0.5s ease;
}

@media (max-width: 767px) {
  .carousel-control-prev,
  .carousel-control-next {
    opacity: 1;
  }
}

@media (max-width: 574px) {
  .dest-listagem .carousel-inner {
    overflow: hidden;
    border-radius: 0px;
  }
  .dest-listagem2 svg {
    height: 40px;
  }

  .dest-listagem2 .carousel-inner {
    border-top-left-radius: 0;
    border-top-right-radius: 27px;
    border-bottom-right-radius: 27px;
    border-bottom-left-radius: 0;
  }
  .dest-listagem .carousel-indicators [data-bs-target] {
    width: 9.5vw;
  }
}

/*fim carrousel imagens*/

/*inicio carrousel tipos*/

/*fim carrousel tipos*/

/*inicio Float label*/
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 1;
  transform: scale(1) translateY(-0.5rem) translateX(1.25rem);
  height: auto;
  padding: 0 2px;
}

.form-floating > .form-control:-webkit-autofill {
  padding-top: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 0.625rem;
}
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 0.625rem;
  border-color: #000;
  box-shadow: none;
}
.boxShadowPares.is-invalid {
  border-color: #dc3545;
}
.form-floating > label {
  opacity: 1;
  -webkit-transform: scale(1) translateY(-0.5rem) translateX(1.25rem);
  -ms-transform: scale(1) translateY(-0.5rem) translateX(1.25rem);
  transform: scale(1) translateY(-0.5rem) translateX(1.25rem);
  height: auto;
  padding: 0 2px;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.24px;
  text-transform: uppercase;
  width: auto;
}

.form-select:focus {
  border-color: #000;
  outline: 0;
  box-shadow: none;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #dc3545 !important;
}

.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
  box-shadow: none;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: 45px;
  font-size: 14px;
  letter-spacing: 0.3px;
}
.form-floating > .form-control {
  padding: 0.25rem 0.75rem;
}
/*fim Float label*/

/*inicio pretty*/
.pretty.p-icon label:before,
.pretty.p-icon label:after,
.pretty.p-icon .icon,
.pretty.p-icon .svg,
.pretty.p-icon .img {
  top: calc(0% - (100% - 67px) - 35%) !important;
}
.pretty {
  line-height: 41px;
  width: 100%;
  height: 100%;
  margin: 0;
}
.pretty .state label {
  font-size: 15px;
  letter-spacing: 0.24px;
}
/*fim pretty*/
/*inicio menu bottom*/
.dropdown-menu {
  border-radius: 0 0 22px 22px;
  padding-top: 0;
  border: none;
  top: calc(100% + 0px) !important;
  left: -40px !important;
}

.navbar .dropup .dropdown-toggle::after {
  display: none;
}

.navbar .dropup > #basic-nav-dropdown4 + .dropdown-menu {
  top: auto !important;
  left: 30px !important;
  border-radius: 22px 22px 0 0;
  bottom: 81px;
}
.navbar .dropup > #basic-nav-dropdown3 + .dropdown-menu {
  top: auto !important;
  left: -50px !important;
  border-radius: 22px 22px 0 0;
  bottom: 81px;
}
/*fim menu bottom*/

/*inicio ImageGallery*/
.image-gallery-fullscreen-button {
  bottom: auto !important;
  top: 0;
}
.image-gallery-slide {
  border-radius: 32.4px;
  overflow: hidden;
}
.image-gallery-slides {
  border-radius: 32.4px;
  box-shadow: 0 12px 19px 0 rgba(0, 0, 0, 0.19);
}
.image-gallery-thumbnail {
  width: 145px !important;
  border-radius: 20px;
  box-shadow: 0px 0px 7px 0 rgb(0 0 0 / 19%);
  border: 0px solid transparent !important;
  margin: 4px;
  overflow: hidden;
}

.image-gallery-slide-wrapper {
  margin-bottom: 15px;
}

.image-gallery-content.fullscreen .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 140px);
}

.app-image-gallery2
  .image-gallery-content.fullscreen
  .image-gallery-slide
  .image-gallery-image {
  max-height: calc(100vh - 140px);
}
.app-image-gallery2
  .image-gallery-content.fullscreen
  .image-gallery-slide-wrapper {
  max-width: 100%;
}
.app-image-gallery2 .image-gallery-content.fullscreen .image-gallery-slides {
  border-radius: 0px;
}
.app-image-gallery2
  .image-gallery-content
  .image-gallery-slide
  .image-gallery-image {
  max-height: 100%;
}
.app-image-gallery2 .image-gallery-thumbnails-wrapper {
  max-width: 1520px;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}
.app-image-gallery2
  .image-gallery-content
  .image-gallery-slide
  .image-gallery-image {
  max-height: 100%;
}
.app-image-gallery2 .image-gallery-slide-wrapper {
  max-width: 1520px;

  margin-right: auto;
  margin-left: auto;
}
.image-gallery-content.fullscreen {
  z-index: 2030 !important;
}

.image-gallery:fullscreen {
  z-index: 2030 !important;
}
.image-gallery:has(.fullscreen) {
  z-index: 2030 !important;
}

@media (max-width: 1600px) {
  .app-image-gallery2 .image-gallery-thumbnails-wrapper {
    max-width: 1320px;
  }

  .app-image-gallery2 .image-gallery-slide-wrapper {
    max-width: 100%;
  }
}
@media (max-width: 1400px) {
  .app-image-gallery2 .image-gallery-thumbnails-wrapper {
    max-width: 1140px;
  }
  .image-gallery-thumbnail {
    width: 106px !important;
  }
}
@media (max-width: 1199px) {
  .app-image-gallery2 .image-gallery-thumbnails-wrapper {
    max-width: 960px;
  }
  .app-image-gallery2 .image-gallery-slides {
    border-radius: 0px;
  }
  .app-image-gallery2 .image-gallery-slide {
    border-radius: 0px;
  }
}
@media (max-width: 992px) {
  .app-image-gallery2 .image-gallery-thumbnails-wrapper {
    max-width: 720px;
  }
}
@media (max-width: 768px) {
  .app-image-gallery2 .image-gallery-thumbnails-wrapper {
    max-width: 540px;
  }
  .image-gallery-slide {
    border-radius: 0px;
  }
  .image-gallery-slides {
    border-radius: 0px;
  }
}
@media (max-width: 576px) {
  .app-image-gallery2 .image-gallery-thumbnails-wrapper {
    max-width: 100%;
  }
}
@media (max-width: 574px) {
  .image-gallery-thumbnail {
    width: 75px !important;
    border-radius: 8px;
  }
  .image-gallery-thumbnail .image-gallery-thumbnail-image {
    border-radius: 8px;
  }
}
@media (hover: hover) and (pointer: fine) {
  .image-gallery-thumbnail:hover {
    border: 0px solid #337ab7 !important;
    box-shadow: 0 12px 19px 0 rgba(0, 0, 0, 0.59);
  }
}

/*fim ImageGallery*/

/* inicio intervalo*/
.intervalo-slider .swiper-wrapper {
  align-items: flex-end;
}

/* fim intervalo*/
.modal-body,
.modal-header {
  padding: 1.5rem;
}

@media (max-width: 767px) {
  .modal-body,
  .modal-header {
    padding: 1rem;
  }
}

/*scrollbar*/
/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
::-webkit-scrollbar {
  width: 10px;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}
/*scrollbar*/

.offcanvas-body {
  overflow-y: overlay;
}

.div-manutencao {
  position: relative;
}

/*ficha viatura*/
.box-recomendados {
  padding: 33.35% 0;
}
.box-more-cars {
  padding: 40px 70px;
  padding-top: 20px;
}

.fullscreen .box-recomendados {
  padding: 23.35% 0;
}

@media (max-width: 1400px) {
  .fullscreen .box-recomendados {
    padding: 33.35% 0;
  }
}
@media (max-width: 574px) {
  .fullscreen .box-recomendados {
    padding: 41.35% 0;
  }
}
/*tradutor*/
.goog-te-combo:not(:first-child) {
  display: none;
  height: 0;
  overflow: hidden;
}
.goog-te-gadget:not(:first-child) {
  display: none;
  height: 0;
  overflow: hidden;
}
.goog-te-banner-frame {
  display: none;
  height: 0 !important;
  overflow: hidden;
}

/*tradutor*/

/*container*/

.container-xxxxl {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
@media (max-width: 767px) {
  .container-xxxxl {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
}
@media (max-width: 574px) {
  .noPadding-xs {
    padding-right: 0;
    padding-left: 0;
  }
}

/*fim Container*/

/*select pesquisa*/

.pesquisa-rapida .rfs-select-container {
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  padding: 0;
}

.rfs-select-container {
  margin-left: -2px;
  margin-top: -2px;
  margin-bottom: -2px;
  width: calc(100% + 4px);
}
.cNukjd {
  align-items: center !important;
  justify-content: center !important;
  font-size: 12px !important;
  font-weight: 100 !important;
  cursor: pointer;
}
.MultiValueWrapper-sc-1vzivtq-0 {
  border-radius: 13px !important;
  overflow: hidden !important;
}
.pesquisa-rapida .ValueWrapper-kcrmu9-1 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/*fim select pesquisa*/

@media (max-width: 576px) {
  .cookies div:first-child {
    flex-grow: 1 !important;
    flex-shrink: 0 !important;
    flex-basis: calc(100% - 91px) !important;
    margin: 0 !important;
    padding: 15px;
  }
  .cookies div:first-child {
    align-items: flex-end !important;
  }
}
/*photoswipe*/
.pswp__top-bar {
  background-color: #212121;
}

.pswp .pswp__hide-on-close {
  opacity: 1 !important;
}

/*photoswipe*/

